import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/security-maturity/case-study",
    label: "CASE STUDY",
  },
]

const RiskReduction = () => (
  <Layout>
    <Seo title="CASE STUDY" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <div className="grid--bg--for-it-ops-csy">
      <section className="section-bio pt-5">
        <div className="container">
          <div className="row">
            <div className="pcsy">
              <div className="section-col-cs">
                <h4>CASE STUDY</h4>
                <hr />
              </div>
              <div className="col-sm-5">
                <h1>A VIGILANT SUPERPOWER</h1>
                <h3>VigilantMEDR</h3>
                <p>
                  When you enter into a partnership with Vigilant, you are
                  signing onto our ever-present SERVICE. You are NOT committing
                  to security tools contracts. Why is this important? 1) Because
                  your company is always evolving, 2) Tools become less
                  effective with time, 3) New tools are always being developed
                  and 4) New threat strategies are always being created. Your
                  security needs to be nimble.
                </p>
                <p>
                  Because Vigilant is client focused and service obsessed, we
                  are always looking for ways to improve your security. And this
                  is part of our approach--to remain tool agnostic so we can be
                  client specific. This approach is possible because we are
                  monitoring your data 24/7/365. Here's an example:
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-blocks-6">
      <div className="section-col-1 pt-5">
        <div className="container">
          <div className="side-binder">
            <h4>PROBLEM</h4>
            <h3>AN OUTDATED SOLUTION</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="csy">
          <p>
            Vigilant enables our clients to be agile by protecting them from
            prolonged commitments to tools. A major retailer client recently
            reached out to us with regard to their Endpoint Security solutions.
            They had been using an endpoint tool that was used by 86% or the
            Fortune 100 and had worked reasonably well. But we had questions, so
            we looked deep into their system and performed a fresh security
            analysis, specifically looking at effectiveness today, not a few
            years ago when this endpoint tool had been integrated. This major
            retailer had significantly grown its footprint to over 100 stores
            since it’s original endpoint tool. We looked at their specific POS
            systems, case studies on their environments’ current uses and
            re-reassessed their evolving threat landscape (in harmony with our
            constant detect, assess, and present process of service).
          </p>
        </div>
      </div>
    </section>
    <section className="section-blocks-6">
      <div className="section-col-1 pt-5">
        <div className="container">
          <div className="side-binder">
            <h4>OUTCOME</h4>
            <h3>VIGILANT UNIQUE TOOL AGILITY</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="csy">
          <p>
            We determined that the client would be much better protected today
            by a different endpoint tool. And since this client was with
            VIGILANT and the contract was with our service, (and not the tools),
            we were able to switch them over, and quickly integrate their entire
            network into the new tool to meet their present-day needs. This is
            an example of one of Vigilant’s superpowers: keeping clients nimble.
            It is alarming to think how many companies are locked into less
            effective or even outdated tools.
          </p>
          <p>
            Security research firms like Forrester and Gartner, make powerful
            assessments about the current state of the global threat landscape
            and the solutions therein. However, that research is generalized and
            not client-specific. For example, our healthcare clients have
            different needs than retail – just as defense clients will have
            needs that differ from manufacturing or financial services. In this
            security environment, the ability to remain nimble and agile for and
            with our clients is critical.
          </p>
        </div>
      </div>
    </section>

    <div className="text-center pb-5">
      <Link to="/contact/" className="nav-section--cta-btn">
        <div className="d-flex align-items-center">
          <span
            style={{ marginLeft: "25px" }}
            className="nav-section--cta-title pr-5"
          >
            schedule a call
          </span>
          <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
        </div>
      </Link>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default RiskReduction
